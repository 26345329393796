<div class="container-fluid w-85">
  <div class="mat-elevation-z2 corners mt-5">
    <div class="table-header row">
      <div class="col-sm-2 d-flex flex-row align-items-center">
        <button mat-raised-button [loading]="isLoading" class="btn-white m-2 text-center"
          matTooltip="Télécharger un PDF" (click)="generatePdf(bookingRecord)"
          data-testid="booking-generate-pdf-button">
          <mat-icon class="colored scale-2 m-3">picture_as_pdf</mat-icon>
        </button>
        <button mat-raised-button [loading]="isLoading" class="btn-white m-2 text-center"
          matTooltip="Renvoyer la confirmation" (click)="resendBookingConfirmation()" [disabled]="isLoading">
          <mat-icon class="colored scale-2 m-3">mail</mat-icon>
        </button>
      </div>
      <div class="col-sm-4">
        <h2 class="m-4">Réservation {{ bookingRecord.businessId }}</h2>
      </div>
      <div class="col-sm-1"></div>

      <div class="col-sm-3 justify-content-center">
        <mat-button-toggle-group class="white mt-3 text-center" [(value)]="bookingRecord.opened"
          (change)="onConsultChange($event.value)">
          <mat-button-toggle [value]="true" data-testid="booking-consult-button">Consulté</mat-button-toggle>
          <mat-button-toggle [value]="false" data-testid="booking-unconsult-button">Non Consulté</mat-button-toggle>
        </mat-button-toggle-group>
        <span class="font-weight-bold white">Dernière consultation :
          {{ dayjs(bookingRecord.openTimeRecord).toDate() | date:'dd/MM/YYYY HH:mm' }}</span>
      </div>
      <div class="col-sm-2 d-flex justify-content-center align-items-center">
        <mat-card class="h-50 w-100 d-flex justify-content-center align-items-center">
          <h4 class="text-center font-weight-bold text-black">
            {{
            bookingRecord.status === BookingStatusEnum.PENDING
            ? 'En attente'
            : bookingRecord.status === BookingStatusEnum.CANCELLED
            ? 'Annulée'
            : 'Confirmée'
            }}
          </h4>
        </mat-card>
      </div>
    </div>
    <div class="row white">
      <mat-divider></mat-divider>
    </div>
    <div class="row justify-content-center table-wrapper">
      <form name="editForm" role="form" class="w-100 position-relative" novalidate [formGroup]="editForm">
        <div *ngIf="bookingRecord.status === BookingStatusEnum.CANCELLED" class="cancelled-overlay">
          <span>CANCELLED</span></div>
        <div class="row w-100 mt-4">
          <div class="col-sm-4">
            <mat-form-field appearance="fill" class="w-100">
              <mat-label>Produit</mat-label>
              <input matInput type="text" formControlName="product" readonly data-testid="booking-product" />
            </mat-form-field>
          </div>
          <div class="col-sm-2">
            <mat-form-field appearance="fill" class="w-75">
              <mat-label>ID Produit</mat-label>
              <input matInput type="text" formControlName="productId" readonly data-testid="booking-product-id" />
            </mat-form-field>
          </div>
          <div class="col-sm-1">
            <mat-form-field appearance="fill" class="w-100">
              <mat-label>Destination</mat-label>
              <input matInput type="text" formControlName="destination" readonly data-testid="booking-destination" />
            </mat-form-field>
          </div>
          <div class="col-sm-1">
            <mat-form-field appearance="fill" class="w-100">
              <mat-label>Date de création</mat-label>
              <input matInput type="text" formControlName="creationDate" readonly data-testid="booking-creation-date" />
            </mat-form-field>
          </div>
          <div class="col-sm-1">
            <mat-form-field appearance="fill" class="w-100">
              <mat-label>Dernière modification</mat-label>
              <input matInput type="text" formControlName="lastUpdate" readonly
                data-testid="booking-last-update-date" />
            </mat-form-field>
          </div>
          <div class="col-sm-1">
            <mat-form-field appearance="fill" class="w-100">
              <mat-label>Canal</mat-label>
              <input matInput type="text" formControlName="distribution" readonly data-testid="booking-distribution" />
            </mat-form-field>
          </div>
          <div class="col-sm-1">
            <mat-form-field appearance="fill" class="w-100">
              <span matPrefix *ngIf="isCurrentlySupplier === false">
                &rarr;</span>
              <mat-label>Producteur</mat-label>
              <input matInput type="text" formControlName="producer" readonly data-testid="booking-producer" />
            </mat-form-field>
          </div>
          <div class="col-sm-1">
            <mat-form-field appearance="fill" class="w-100">
              <span matPrefix *ngIf="isCurrentlySupplier === true">
                &rarr;</span>
              <mat-label>Distributeur</mat-label>
              <input matInput type="text" formControlName="supplier" readonly data-testid="booking-supplier" />
            </mat-form-field>
          </div>
        </div>
        <div class="row w-100 mt-4">
          <div class="col-sm-4">
            <div class="timeline w-100 h-100">
              <div class="container left">
                <div class="date">{{ dayjs(bookingRecord.startDate).toDate() | date: 'dd/MM/YYYY' }}</div>
                <mat-icon>{{
                  bookingRecord.startCityName ? 'flight_takeoff' : 'directions_walk'
                  }}</mat-icon>
                <div class="content">
                  <h2>
                    Voyage vers la destination :
                    {{ bookingRecord.productRecord?.destinationName }}
                  </h2>
                  <p>
                    {{ bookingRecord.startCityName
                    ? 'Au départ de ' + bookingRecord.startCityName
                    : 'Terrestre Seul' }} {{ startDelay }}
                  </p>
                </div>
              </div>
              <div class="container right">
                <div class="date"></div>
                <mat-icon>hotel</mat-icon>
                <div class="content">
                  <h2>{{ bookingRecord.nbNights }} - {{ bookingRecord.productRecord?.productName }}</h2>
                  <p>{{
                    bookingRecord.productRecord?.productType || 'Circuit'}}
                    {{bookingRecord.productRecord?.categoryName}}
                  </p>
                  <p>Voir détail de la prestation.</p>
                </div>
              </div>
              <div class="container left">
                <div class="date">{{ dayjs(bookingRecord.endDate).toDate() | date: 'dd/MM/YYYY' }}</div>
                <mat-icon class="mirror">{{
                  bookingRecord.startCityName ? 'flight_takeoff' : 'directions_walk'
                  }}
                </mat-icon>
                <div class="content">
                  <h2>
                    Voyage retour
                    {{
                    bookingRecord.startCityName
                    ? 'vers ' + bookingRecord.startCityName
                    : 'Non inclus'
                    }}
                  </h2>
                  <p>
                    Depuis la destination :
                    {{ bookingRecord.productRecord?.destinationName }}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div class="col-sm-2"></div>
          <div class="col-sm-6">
            <mat-card class="corners mat-elevation-z2 bordered w-100">
              <mat-card-content>
                <booking-record-bill [bookingRecord]="bookingRecord"></booking-record-bill>
              </mat-card-content>
            </mat-card>
          </div>
        </div>
      </form>
      <mat-divider> </mat-divider>
      <div class="w-100">
        <mat-tab-group mat-stretch-tabs="false" class="tab-group-500">
          <mat-tab label="Passagers" *ngIf="bookingRecord.travelerRecord">
            <booking-record-traveler [travelerRecord]="bookingRecord.travelerRecord"
              [customerInfo]="bookingRecord.customerInfo"></booking-record-traveler>
          </mat-tab>
          <mat-tab label="Vols" *ngIf="bookingRecord.flightRecord && bookingRecord.flightRecord.offer">
            <booking-record-flight [flightRecord]="bookingRecord.flightRecord"
              [travelerRecord]="bookingRecord.travelerRecord" [bookingPrice]="bookingRecord.bookingPrice"
              [creationDate]="bookingRecord.creationDate"></booking-record-flight>
          </mat-tab>
          <mat-tab label="Hébergement" *ngIf="bookingRecord.accommodationRecord">
            <div class="fixed-height-300">
              <booking-record-accommodation
                [accommodationRecord]="bookingRecord.accommodationRecord"></booking-record-accommodation>
            </div>
          </mat-tab>
          <mat-tab label="Assurances" *ngIf="bookingRecord.insuranceRecord">
            <div class="fixed-height-300">
              <booking-record-insurance [insuranceRecord]="bookingRecord.insuranceRecord"
                [travelerRecord]="bookingRecord.travelerRecord"></booking-record-insurance>
            </div>
          </mat-tab>
          <mat-tab label="Locations de voiture" *ngIf="bookingRecord.carRentalRecord">
            <div class="fixed-height-300">
              <booking-record-car-rental [carRentalRecord]="bookingRecord.carRentalRecord"></booking-record-car-rental>
            </div>
          </mat-tab>
          <mat-tab label="Options" *ngIf="bookingRecord.optionRecord">
            <div class="fixed-height-300">
              <booking-record-option [optionRecord]="bookingRecord.optionRecord"></booking-record-option>
            </div>
          </mat-tab>
          <mat-tab label="Détails">
            <div class="row col-12 fixed-height-300">
              <div class="row w-100 text-center background m-1 p-1 corners font-weight-bold">
                Le prix comprend
              </div>
              <div class="col-12" [innerHTML]="bookingRecord.productRecord?.priceInDescription"></div>
              <br />
              <div class="row w-100 text-center background m-1 p-1 corners font-weight-bold">
                Le prix ne comprend pas
              </div>
              <div class="col-12" [innerHTML]="bookingRecord.productRecord?.priceOffDescription"></div>
            </div>
          </mat-tab>
          <mat-tab label="Conditions d'annulation" *ngIf="bookingRecord.distributionRecord.conditions">
            <div class="fixed-height-500">
              <div class="row justify-content-center w-100 m-3"
                *ngFor="let condition of bookingRecord.distributionRecord.conditions">
                <div class="col-9">
                  <p>{{ condition }}</p>
                </div>
              </div>
            </div>
          </mat-tab>
          <mat-tab label="Remarques" *ngIf="bookingRecord.customerInfo.remark">
            <div class="row m-4">
              <span class="col-sm-2 centered-span">Commentaire client : </span>
              <span class="col-sm-10">{{ bookingRecord.customerInfo.remark }}</span>
            </div>
          </mat-tab>
          <mat-tab label="Paiements" *ngIf="bookingRecord.paymentRecords">
            <div class="fixed-height-300">
              <booking-record-payment [paymentRecords]="bookingRecord.paymentRecords"></booking-record-payment>
            </div>
          </mat-tab>
          <mat-tab label="Finances" *ngIf="bookingRecord && !isCurrentlySupplier && !producerId">
            <booking-record-bill [bookingRecord]="bookingRecord" [producerView]="true"></booking-record-bill>
          </mat-tab>

          <mat-tab label="Données Orchestra"
            [disabled]="!bookingRecord.rawRecord?.originalRequest && !bookingRecord.rawRecord?.originalQuoteResponse && !bookingRecord?.rawRecord?.originalResponse">
            <ng-template matTabContent>
              <mat-tab-group mat-stretch-tabs="false" class="tab-group-500">

                <mat-tab label="Requête Booking" [disabled]="!bookingRecord.rawRecord?.originalRequest">
                  <ng-template matTabContent>
                    <ngx-codemirror class="h-100" [ngModel]="bookingRecord.rawRecord?.originalRequest | XmlFormatPipe"
                      [options]="options"></ngx-codemirror>
                  </ng-template>
                </mat-tab>
                <mat-tab label="Réponse Quote" [disabled]="!bookingRecord.rawRecord?.originalQuoteResponse">
                  <ng-template matTabContent>
                    <ngx-codemirror [ngModel]="bookingRecord.rawRecord?.originalQuoteResponse | XmlFormatPipe"
                      [options]="options"></ngx-codemirror>
                  </ng-template>
                </mat-tab>
                <mat-tab label="Réponse Booking" [disabled]="!bookingRecord.rawRecord?.originalResponse">
                  <ng-template matTabContent>
                    <ngx-codemirror [ngModel]="bookingRecord.rawRecord?.originalResponse | XmlFormatPipe"
                      [options]="options"></ngx-codemirror>
                  </ng-template>
                </mat-tab>
              </mat-tab-group>
            </ng-template>
          </mat-tab>
          <mat-tab label="Données Vols"
            [disabled]="!bookingRecord.rawRecord?.originalFlightRequest && !bookingRecord.rawRecord?.originalFlightResponse">
            <ng-template matTabContent>
              <mat-tab-group>
                <mat-tab label="Requête API Vol (Travelport)"
                  [disabled]="!bookingRecord.rawRecord?.originalFlightRequest">
                  <ng-template matTabContent>
                    <ngx-codemirror [ngModel]="bookingRecord.rawRecord?.originalFlightRequest | XmlFormatPipe"
                      [options]="options"></ngx-codemirror>
                  </ng-template>
                </mat-tab>
                <mat-tab label="Réponse API Vol (Travelport)"
                  [disabled]="!bookingRecord.rawRecord?.originalFlightResponse">
                  <ng-template matTabContent>
                    <ngx-codemirror [ngModel]="bookingRecord.rawRecord?.originalFlightResponse | XmlFormatPipe"
                      [options]="options"></ngx-codemirror>
                  </ng-template>
                </mat-tab>
              </mat-tab-group>
            </ng-template>
          </mat-tab>
          <mat-tab label="Données Assurances"
            [disabled]="!bookingRecord.rawRecord?.originalInsuranceRequest && !bookingRecord.rawRecord?.originalInsuranceResponse">
            <ng-template matTabContent>
              <mat-tab-group>
                <mat-tab label="Requête API Assurance (Presence XML)"
                  [disabled]="!bookingRecord.rawRecord?.originalInsuranceRequest">
                  <ng-template matTabContent>
                    <ngx-codemirror [ngModel]="bookingRecord.rawRecord?.originalInsuranceRequest | XmlFormatPipe"
                      [options]="options"></ngx-codemirror>
                  </ng-template>
                </mat-tab>
                <mat-tab label="Réponse API Assurance (Presence XML)"
                  [disabled]="!bookingRecord.rawRecord?.originalInsuranceResponse">
                  <ng-template matTabContent>
                    <ngx-codemirror [ngModel]="bookingRecord.rawRecord?.originalInsuranceResponse | XmlFormatPipe"
                      [options]="options"></ngx-codemirror>
                  </ng-template>
                </mat-tab>
              </mat-tab-group>
            </ng-template>
          </mat-tab>




        </mat-tab-group>
      </div>
    </div>
  </div>
</div>