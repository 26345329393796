<table class="table table-striped">
  <thead>
    <tr>
      <th><span class="font-weight-bold">Type</span></th>
      <th><span class="font-weight-bold">Catégorie</span></th>
      <th>Qté</th>
      <th>ADT</th>
      <th>CHD</th>
      <th>BEBE</th>
      <th></th>
      <th *ngIf="">Prix</th>
    </tr>
  </thead>
  <tbody *ngFor="let room of accommodationRecord.rooms; let i = index">
    <tr>
      <td>
        <span style="font-size: small">{{ room.type }}</span>
      </td>
      <td>
        <span style="font-size: small">{{ room.category | AccommodationRecordRoomCategoryPipe }}</span>
      </td>
      <td>{{ 1 }}</td>
      <td>{{ room.adult }}</td>
      <td>{{ room.child }}</td>
      <td>{{ room.baby }}</td>
      <td></td>
      <td>
        {{ room.price.toFixed(2) + '€' }}
      </td>
    </tr>
  </tbody>
</table>