<div class="fixed-height-1000" *ngIf="producerView;else simpleView">
  <div class="row background m-1 p-1 corners font-weight-bold">
    <div class="col-sm-11">
      <h5>TOTAL ACHATS</h5>
    </div>
    <div class="col-sm-1">
      <h5>{{ totalPriceBought + '&nbsp;€' }}</h5>
    </div>
  </div>
  <div class="row col-sm-12">
    <div class="row background m-1 p-1 corners font-weight-bold">
      <div class="col-sm-11">
        <h5>CONTRATS</h5>
      </div>
      <div class="col-sm-1">
        <h5>{{ contractAndMealPrice }}&nbsp;€</h5>
      </div>
    </div>
    <table class="table table-striped">
      <thead>
        <tr>
          <th>Type</th>
          <th>Catégorie</th>
          <th>Statut</th>
          <th></th>
          <th></th>
          <th>Qté</th>
          <th>ADT</th>
          <th>CHD</th>
          <th>BEBE</th>
          <th></th>
          <th>Total</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let room of bookingRecord.accommodationRecord?.rooms">
          <td>
            <span style="font-size: small">{{ room.type }}</span>
          </td>
          <td>
            <span style="font-size: small">{{ room.category | AccommodationRecordRoomCategoryPipe }}</span>
          </td>
          <td>
            <span [class.green-colored]="room.status === BookingSubStatusEnum.OK"
              [class.red-colored]="room.status === BookingSubStatusEnum.KO"
              [class.orange-colored]="room.status === BookingSubStatusEnum.NO">{{ room.status }}</span>
          </td>
          <td></td>
          <td></td>
          <td>{{ 1 }}</td>
          <td>{{ room.adult }}</td>
          <td>{{ room.child }}</td>
          <td>{{ room.baby }}</td>
          <td></td>
          <td>{{ room.price.toFixed(2) }}&nbsp;€</td>
        </tr>

        <tr *ngFor="let meal of bookingRecord.mealRecords">
          <td>
            <span style="font-size: small">{{
              meal?.name
              }}</span>
          </td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td>{{ bookingRecord.travelerRecord.adultNumber }}</td>
          <td>{{ bookingRecord.travelerRecord.childNumber }}</td>
          <td>{{ bookingRecord.travelerRecord.babyNumber }} </td>
          <td></td>
          <td>
            <ng-container *ngIf="meal.price && meal.price !== 0">{{
              meal.price?.toFixed(2) + '&nbsp;€'
              }}</ng-container>
            <ng-container *ngIf="!meal.price || meal.price === 0">inclus</ng-container>
          </td>

        </tr>
      </tbody>
    </table>
  </div>
  <div class="row col-sm-12" *ngIf="bookingRecord.flightRecord && bookingRecord.flightRecord.offer">
    <div class="row background m-1 p-1 corners font-weight-bold">
      <div class="col-sm-11">
        <h5>VOLS</h5>
      </div>
      <div class="col-sm-1">
        <h5>{{ getRealFlightPrice(true) }}&nbsp;€</h5>
      </div>
    </div>
    <table class="table table-striped">
      <thead>
        <tr>
          <th>Source</th>
          <th>Référence</th>
          <th *ngIf="bookingRecord.flightRecord.ticketReference">Ticket</th>
          <th>Statut</th>
          <th>Vols TTC</th>
          <th>Bagages</th>
          <th></th>
          <th>Emis</th>
          <th>Mode</th>
          <th>Total HT</th>
          <th>Taxes</th>
          <th>Total</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>
            {{
            bookingRecord.flightRecord.source || '-'
            }}
          </td>
          <td>{{ bookingRecord.flightRecord.supplierLocatorCode }} / {{ bookingRecord.flightRecord.locatorCode }}</td>
          <td *ngIf="bookingRecord.flightRecord.ticketReference">
            {{ bookingRecord.flightRecord.ticketReference }}
          </td>
          <td>
            <span [class.green-colored]="bookingRecord.flightRecord.status === 'OK'"
              [class.orange-colored]="bookingRecord.flightRecord.status === 'NO'"
              [class.red-colored]="bookingRecord.flightRecord.status === 'KO'">{{ bookingRecord.flightRecord.status
              }}</span>
          </td>
          <td>{{ getRealFlightPrice(false) }}&nbsp;€</td>
          <td>{{ (bookingRecord.bookingPrice.paidLuggagePrice ||
            bookingRecord.flightRecord.offer.bagRealPrice)?.toFixed(2) || '-' }}&nbsp;€</td>
          <td></td>
          <td>{{ bookingRecord.creationDate | date: 'dd/MM/YYYY' }}</td>
          <td></td>
          <td></td>
          <td>
            {{ bookingRecord.flightRecord.offer.paymentMode
            ? bookingRecord.flightRecord.offer.paymentMode
            : '-'
            }}
          </td>
          <td>{{ bookingRecord.bookingPrice.untaxedFlightPackage.toFixed(2) }}&nbsp;€</td>
          <td>
            {{(bookingRecord.bookingPrice.taxPrice === null
            ? (Number(bookingRecord.flightRecord.offer.total) - Number(bookingRecord.flightRecord.offer.base) -
            (bookingRecord.flightRecord.offer.bagRealPrice ?
            Number(bookingRecord.flightRecord.offer.bagRealPrice) : 0)).toFixed(2)
            : Number(bookingRecord.bookingPrice.taxPrice).toFixed(2)) + ''
            || '-'
            }}&nbsp;€
          </td>
          <td>{{ getRealFlightPrice(true) }}&nbsp;€</td>
        </tr>
      </tbody>
    </table>
  </div>
  <div class="row col-sm-12" *ngIf="bookingRecord.insuranceRecord">
    <div class="row background m-1 p-1 corners font-weight-bold">
      <div class="col-sm-11">
        <h5>ASSURANCES</h5>
      </div>
      <div class="col-sm-1">
        <h5>{{ bookingRecord.insuranceRecord.insuranceConnectorPrice }}&nbsp;€</h5>
      </div>
    </div>
    <table class="table table-striped">
      <thead>
        <tr>
          <th>Source</th>
          <th>Informations</th>
          <th>Référence</th>
          <th></th>
          <th>Statut</th>
          <th>Date</th>
          <th>ADT</th>
          <th>CHD</th>
          <th>BEBE</th>
          <th></th>
          <th>Total</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>
            {{bookingRecord.insuranceRecord.insuranceName}}
          </td>
          <td>
            {{bookingRecord.insuranceRecord.insuranceTypeName}}
          </td>
          <td>
            {{bookingRecord.insuranceRecord.insuranceConnectorRef}}
          </td>
          <td></td>
          <td>
            <span [class.green-colored]="bookingRecord.insuranceRecord.status === 'OK'"
              [class.orange-colored]="bookingRecord.insuranceRecord.status === 'NO'"
              [class.red-colored]="bookingRecord.insuranceRecord.status === 'KO'">{{
              bookingRecord.insuranceRecord.status }}</span>
          </td>
          <td>
            {{ bookingRecord.insuranceRecord.insuranceConnectorTimeStamp | date: 'dd/MM/yyyy' }}
          </td>
          <td>{{ bookingRecord.travelerRecord.adultNumber }}</td>
          <td>{{ bookingRecord.travelerRecord.childNumber }}</td>
          <td>{{ bookingRecord.travelerRecord.babyNumber }}</td>
          <td></td>
          <td>{{ bookingRecord.insuranceRecord.insuranceConnectorPrice ? (
            (parseFloat(bookingRecord.insuranceRecord.insuranceConnectorPrice) / 100).toFixed(2)) : 0 }}&nbsp;€</td>
        </tr>
      </tbody>
    </table>
  </div>
  <div class="row col-sm-12" *ngIf="bookingRecord.carRentalRecord">
    <div class="row background m-1 p-1 corners font-weight-bold">
      <div class="col-sm-11">
        <h5>LOCATIONS DE VOITURE</h5>
      </div>
      <div class="col-sm-1">
        <h5>{{ bookingRecord.carRentalRecord.carRentalOfferPrice }}&nbsp;€</h5>
      </div>
    </div>
    <table class="table table-striped">
      <thead>
        <tr>
          <th>Source</th>
          <th>ID</th>
          <th></th>
          <th>Référence</th>
          <th></th>
          <th>Statut</th>
          <th></th>
          <th>ADT</th>
          <th>CHD</th>
          <th>BEBE</th>
          <th></th>
          <th>Total</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>{{ bookingRecord.carRentalRecord.carRentalProvider }}</td>
          <td>{{ bookingRecord.carRentalRecord.carRentalOfferToken }}</td>
          <td></td>
          <td>{{ bookingRecord.carRentalRecord.carRentalBookingReference }}</td>
          <td></td>
          <td>
            <span [class.green-colored]="bookingRecord.carRentalRecord.carRentalProvider"
              [class.red-colored]="!bookingRecord.carRentalRecord.carRentalProvider">{{
              bookingRecord.carRentalRecord.status }}</span>
          </td>
          <td>

          </td>
          <td>{{ bookingRecord.travelerRecord.adultNumber }}</td>
          <td>{{ bookingRecord.travelerRecord.childNumber }}</td>
          <td>{{ bookingRecord.travelerRecord.babyNumber }}</td>
          <td></td>
          <td>{{bookingRecord.carRentalRecord.carRentalOfferPrice}}&nbsp;€</td>
        </tr>
      </tbody>
    </table>
  </div>
  <div class="row col-sm-12" *ngIf="bookingRecord.optionRecord">
    <div class="row background m-1 p-1 corners font-weight-bold">
      <div class="col-sm-11">
        <h5>OPTIONS</h5>
      </div>
      <div class="col-sm-1">
        <h5>
          {{
          bookingRecord.bookingPrice.totalOptionPrice.toFixed(2) +
          '&nbsp;€'
          }}
        </h5>
      </div>
    </div>
    <table class="table table-striped">
      <thead>
        <tr>
          <th>Référence</th>
          <th>ID</th>
          <th>Statut</th>
          <th></th>
          <th></th>
          <th>Qté</th>
          <th>ADT</th>
          <th>CHD</th>
          <th>BEBE</th>
          <th></th>
          <th>Total</th>
        </tr>
      </thead>
      <tbody></tbody>
      <tbody *ngFor="let option of bookingRecord.optionRecord.options || []; let i = index">
        <tr *ngIf="option.base === TypeBaseEnum.UNIT">
          <td>
            <span style="font-size: small" [matTooltip]=" option.name ">{{option.name | ShortTextPipe:'10'}}</span>
          </td>
          <td>{{ option.name }}</td>
          <td>OK</td>
          <td></td>
          <td></td>
          <td>{{ option.nbUnits}}</td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td *ngIf="option.name">
            <ng-container *ngIf="!option.mandatory">
              {{ option.totalPrice?.toFixed(2) }}&nbsp;€
            </ng-container>
            <ng-container *ngIf="option.mandatory">
              inclus
            </ng-container>
          </td>
        </tr>
        <tr *ngIf="option.base === TypeBaseEnum.PERSON">
          <td>
            <span style="font-size: small" [matTooltip]=" option.name ">{{option.name | ShortTextPipe:'10'}}</span>
          </td>
          <td>{{ option.name }}</td>
          <td>OK</td>
          <td></td>
          <td></td>
          <td></td>
          <td>{{ option.nbAdults }}</td>
          <td>{{ option.nbChildren }}</td>
          <td></td>
          <td></td>
          <td *ngIf="option.name">
            <ng-container *ngIf="!option.mandatory">
              {{ option.totalPrice?.toFixed(2) }}&nbsp;€
            </ng-container>
            <ng-container *ngIf="option.mandatory">
              inclus
            </ng-container>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>

<ng-template #simpleView>
  <table class="table w-100 h-100">
    <thead>
      <tr>
        <th>Détails</th>
        <th></th>
        <th>Qté</th>
        <th>ADT</th>
        <th>CHD</th>
        <th>BB</th>
        <th></th>
        <th>Com</th>
        <th>Montant</th>
      </tr>
    </thead>
    <tbody *ngIf="bookingRecord.accommodationRecord?.rooms" style="border-radius: 10px;">
      <tr class="background">
        <td><span style="font-weight: bold"> FORFAIT </span></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td> <span style="font-size: small">
            {{bookingRecord.bookingPrice.packageCommission.toFixed(2)}}&nbsp;€ </span></td>
        <td> <span> {{bookingRecord.bookingPrice.packagePrice.toFixed(2)}}&nbsp;€ </span> </td>
      </tr>
    </tbody>
    <tbody *ngIf="bookingRecord.accommodationRecord?.rooms">
      <tr class="background corners">
        <td><span class="font-italic"> Hébergement </span> </td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
      </tr>
    </tbody>
    <tbody *ngFor="let room of bookingRecord.accommodationRecord?.rooms || [];  let i = index">
      <tr>
        <td><span style="font-size: small" [matTooltip]="room.type">{{room.type | ShortTextPipe:'10'}}</span></td>
        <td><span style="font-size: small">{{room.category || ''}}</span></td>
        <td>{{1}}</td>
        <td>{{room.adult}}</td>
        <td>{{room.child}}</td>
        <td>{{room.baby}}</td>
        <td></td>
        <td></td>
        <td></td>
      </tr>
    </tbody>
    <tbody *ngFor="let meal of bookingRecord.mealRecords || [];  let i = index">
      <tr>
        <td><span style="font-size: small">{{meal.name}}</span></td>
        <td></td>
        <td>{{bookingRecord.travelerRecord.adultNumber + bookingRecord.travelerRecord.childNumber +
          bookingRecord.travelerRecord.babyNumber}}</td>
        <td>{{bookingRecord.travelerRecord.adultNumber}}</td>
        <td>{{bookingRecord.travelerRecord.childNumber}}</td>
        <td>{{bookingRecord.travelerRecord.babyNumber}}</td>
        <td></td>
        <td> </td>
        <td> <span><ng-container *ngIf="meal.price && meal.price !== 0">{{
              meal.price?.toFixed(2) + '&nbsp;€'
              }}</ng-container>
            <ng-container *ngIf="!meal.price || meal.price === 0">inclus</ng-container></span></td>
      </tr>
    </tbody>
    <tbody *ngIf="bookingRecord.flightRecord">
      <tr class="background corners">
        <td> <span class="font-italic"> Transport </span></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
      </tr>
    </tbody>
    <tbody *ngIf="bookingRecord.flightRecord">
      <tr>
        <td><span style="font-size: small"> Vols </span></td>
        <td></td>
        <td>{{bookingRecord.travelerRecord.adultNumber + bookingRecord.travelerRecord.childNumber +
          bookingRecord.travelerRecord.babyNumber}}</td>
        <td>{{bookingRecord.travelerRecord.adultNumber}}</td>
        <td>{{bookingRecord.travelerRecord.childNumber}}</td>
        <td>{{bookingRecord.travelerRecord.babyNumber}}</td>
        <td></td>
        <td></td>
        <td> <span>Inclus </span> </td>
      </tr>
    </tbody>
    <tbody *ngIf="bookingRecord.flightRecord">
      <tr>
        <td><span style="font-size: small"> Taxes </span></td>
        <td></td>
        <td>{{bookingRecord.travelerRecord.adultNumber + bookingRecord.travelerRecord.childNumber +
          bookingRecord.travelerRecord.babyNumber}}</td>
        <td>{{bookingRecord.travelerRecord.adultNumber}}</td>
        <td>{{bookingRecord.travelerRecord.childNumber}}</td>
        <td>{{bookingRecord.travelerRecord.babyNumber}}</td>
        <td></td>
        <td></td>
        <td><span>{{bookingRecord.bookingPrice.taxPrice.toFixed(2)}} €</span></td>
      </tr>
    </tbody>
    <tbody *ngIf="bookingRecord.flightRecord && (bookingRecord.flightRecord.bags?.length || 0) > 0">
      <tr>
        <td><span style="font-size: small"> Bagages </span></td>
        <td></td>
        <td>{{bookingRecord.flightRecord.bags.length}}</td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td><span style="font-size: small">
            {{bookingRecord.bookingPrice.totalLuggageCommission ?
            bookingRecord.bookingPrice.totalLuggageCommission.toFixed(2) : '0.00'}} € </span></td>
        <td><span> {{bookingRecord.bookingPrice.luggagePrice.toFixed(2)}} € </span></td>
      </tr>
    </tbody>
    <tbody *ngIf="(bookingRecord.optionRecord?.options?.length || 0) > 0">
      <tr class="background corners">
        <td> <span class="font-italic"> Options </span></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
      </tr>
    </tbody>
    <tbody *ngFor="let option of bookingRecord.optionRecord?.options || [];  let i = index">
      <tr *ngIf="option.base === TypeBaseEnum.UNIT">
        <td>
          <span style="font-size: small" [matTooltip]=" option.name ">{{option.name | ShortTextPipe:'10'}}</span>
        </td>
        <td></td>
        <td>{{ option.nbUnits}}</td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td>
          <ng-container *ngIf="!option.mandatory">
            <span style="font-size: small">
              {{ option.commission?.toFixed(2) }} € </span>
          </ng-container>
        </td>
        <td><ng-container *ngIf="!option.mandatory"><span> {{option.totalPrice?.toFixed(2)}} €</span></ng-container>
          <ng-container *ngIf="option.mandatory"><span> inclus</span></ng-container>
        </td>
      </tr>
      <tr *ngIf="option.base === TypeBaseEnum.PERSON">
        <td>
          <span style="font-size: small" [matTooltip]=" option.name ">{{option.name | ShortTextPipe:'10'}}</span>
        </td>
        <td></td>
        <td></td>
        <td>{{option.nbAdults || ''}}</td>
        <td>{{option.nbChildren || ''}}</td>
        <td></td>
        <td></td>
        <td>
          <ng-container *ngIf="!option.mandatory">
            <span style="font-size: small">
              {{ option.commission?.toFixed(2) }} € </span>
          </ng-container>
        </td>
        <td><ng-container *ngIf="!option.mandatory"><span> {{option.totalPrice?.toFixed(2)}} €</span></ng-container>
          <ng-container *ngIf="option.mandatory"><span> inclus</span></ng-container>
        </td>
      </tr>
    </tbody>
    <tbody *ngIf="bookingRecord.insuranceRecord">
      <tr class="background corners">
        <td><span class="font-italic">Assurances </span></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
      </tr>
    </tbody>
    <tbody *ngIf="bookingRecord.insuranceRecord">
      <tr>
        <td><span style="font-size: small">{{bookingRecord.insuranceRecord.insuranceName}}</span></td>
        <td><span style="font-size: small"
            [matTooltip]="bookingRecord.insuranceRecord.insuranceTypeName">{{bookingRecord.insuranceRecord.insuranceTypeName
            | ShortTextPipe:'10'}}</span></td>
        <td></td>
        <td>{{bookingRecord.travelerRecord.adultNumber}}</td>
        <td>{{bookingRecord.travelerRecord.childNumber}}</td>
        <td>{{bookingRecord.travelerRecord.babyNumber}}</td>
        <td></td>
        <td><span style="font-size: small">
            {{bookingRecord.bookingPrice.totalInsuranceCommission.toFixed(2) || '0.00'}} € </span></td>
        <td><span>{{bookingRecord.bookingPrice.insurancePrice.toFixed(2)}} €</span></td>
      </tr>
    </tbody>

    <tbody *ngIf="bookingRecord.carRentalRecord">
      <tr class="background corners">
        <td><span class="font-italic">Locations de voiture </span></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
      </tr>
    </tbody>
    <tbody *ngIf="bookingRecord.carRentalRecord">
      <tr>
        <td><span style="font-size: small">{{bookingRecord.carRentalRecord.carRentalProvider}}</span></td>
        <td><span style="font-size: small"
            [matTooltip]="bookingRecord.carRentalRecord.carRentalName">{{bookingRecord.carRentalRecord.carRentalName |
            ShortTextPipe:'10'}}</span></td>
        <td></td>
        <td>{{bookingRecord.travelerRecord.adultNumber}}</td>
        <td>{{bookingRecord.travelerRecord.childNumber}}</td>
        <td>{{bookingRecord.travelerRecord.babyNumber}}</td>
        <td></td>
        <td><span *ngIf="bookingRecord.carRentalRecord.carRentalMandatory === false" style="font-size: small">
            {{bookingRecord.carRentalRecord.carRentalCommission.toFixed(2) || '0.00'}} € </span></td>
        <td><span *ngIf="bookingRecord.carRentalRecord.carRentalMandatory === false">{{
            bookingRecord.carRentalRecord.carRentalTotalPrice.toFixed(2)|| '0.00'}} €</span></td>
      </tr>
    </tbody>
  </table>
  <div class="row w-100">
    <div class="col-sm-6">
    </div>
    <div class="col-sm-6">
      <table class="w-100">
        <tbody class="w-100 background corners">
          <tr>
            <td>
              <h5>TOTAL</h5>
            </td>
            <td></td>
            <td></td>
            <td>
              <h5 class="font-weight-bold">{{bookingRecord.bookingPrice.totalPrice.toFixed(2)}}&nbsp;€</h5>
            </td>
          </tr>
        </tbody>
        <tbody class="w-100">
          <tr *ngIf="bookingRecord.bookingPrice.commissionPercent && bookingRecord.bookingPrice.totalCommission">
            <td><span class="font-italic">Commission</span></td>
            <td><span class="font-italic"></span></td>
            <td><span class="font-italic"></span></td>
            <td><span>{{bookingRecord.bookingPrice.totalCommission.toFixed(2)}}&nbsp;€</span></td>
          </tr>
        </tbody>
        <tbody class="w-100 background corners">
          <tr *ngIf="bookingRecord.bookingPrice.commissionPercent && bookingRecord.bookingPrice.totalCommission">
            <td>
              <h5>A PAYER</h5>
            </td>
            <td></td>
            <td></td>
            <td>
              <h5 class="font-weight-bold">{{(bookingRecord.bookingPrice.totalPrice -
                bookingRecord.bookingPrice.totalCommission).toFixed(2)}}&nbsp;€</h5>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</ng-template>