import { Pipe, PipeTransform } from '@angular/core';
import { BookingStatusEnum } from '@pulpo/pulpo-models';

@Pipe({
  name: 'BookingStatusPipe',
  standalone: true,
  pure: true,
})
export class BookingStatusPipe implements PipeTransform {
  transform(value?: BookingStatusEnum): string {
    switch (value) {
      case BookingStatusEnum.DRAFT:
        return 'DRAFT';
      case BookingStatusEnum.VALIDATED:
        return 'OK';
      case BookingStatusEnum.CANCELLED:
        return 'CXL';
      default:
        return 'NO';
    }
  }
}
