import {
  HTTP_INTERCEPTORS,
  provideHttpClient,
  withInterceptorsFromDi,
} from '@angular/common/http';
import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';
import { NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { PulpoCoreModule } from './core.module';
import { HttpCacheInterceptorModule } from '@ngneat/cashew';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { environment } from '../environments/environment';
import { APP_CONFIG } from '@pulpo/app-config';
import {
  AuthExpiredInterceptor,
  AuthInterceptor,
  ErrorHandlerInterceptor,
  LoadingInterceptor,
  AuthGuard,
  RoleGuard,
  MainLoaderComponent,
  NgbDateCustomParserFormatter,
  TranslocoRootModule,
} from '@pulpo/pulpo-commons';
import { RouteReuseStrategy, Router } from '@angular/router';
import { CustomReuseStrategy } from '@pulpo/pulpo-api';
import { AppComponent } from './app.component';
import * as Sentry from '@sentry/angular';
import {
  provideNgxWebstorage,
  withNgxWebstorageConfig,
  withSessionStorage,
} from 'ngx-webstorage';

@NgModule({
  declarations: [AppComponent],
  bootstrap: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    PulpoCoreModule,
    MatSnackBarModule,
    HttpCacheInterceptorModule.forRoot(),
    TranslocoRootModule,
    MainLoaderComponent,
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthExpiredInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorHandlerInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LoadingInterceptor,
      multi: true,
    },
    {
      provide: NgbDateParserFormatter,
      useClass: NgbDateCustomParserFormatter,
    },
    {
      provide: STEPPER_GLOBAL_OPTIONS,
      useValue: { displayDefaultIndicatorType: false },
    },
    {
      provide: APP_CONFIG,
      useValue: environment,
    },
    {
      provide: RouteReuseStrategy,
      useClass: CustomReuseStrategy,
    },
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
      }),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true,
    },
    AuthGuard,
    RoleGuard,
    provideHttpClient(withInterceptorsFromDi()),
    provideNgxWebstorage(
      withNgxWebstorageConfig({ prefix: 'pulpo', separator: '-' }),
      withSessionStorage()
    ),
  ],
})
export class AppModule {}
